<template>
    <auth-layout>
      <h2 class="welcome">Reaktivasi Akun</h2>
        <span class="welcome-note">
          Mohon masukkan kata sandi baru dan mengonfirmasinya
        </span>
      <form @submit.prevent="submit" method="post">
      <div class="form-group" style="width:491px; margin-top: 100px;">
        <div class="form-group">
          <label class="col-form-label" for="inputPassword">Kata Sandi Baru</label>
          <div class="input-group mb-3">
            <input
              id="inputPassword"
              :type="showPassword ? 'text' : 'password'"
              :class="error ? 'form-control is-invalid' : 'form-control login-focus'"
              autocomplete="off"
              v-model="form.new_password"
              placeholder="Masukkan Kata Sandi"
              :disabled="loading"
            />
            <div class="input-group-append">
            <span class="input-group-text" @click="showPwd()">
              <i :class="showPassword == true ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
            </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-form-label" for="inputPasswordConfirmation">Konfirmasi Kata Sandi Baru</label>
          <div class="input-group mb-3">
            <input
              id="inputPasswordConfirmation"
              :type="showPasswordConfirm ? 'text' : 'password'"
              :class="error ? 'form-control is-invalid' : 'form-control login-focus'"
              autocomplete="off"
              v-model="new_password_confirmation"
              placeholder="Masukkan Kata Sandi"
              @change="checkPwd"
              :disabled="loading"
            />
            <div class="input-group-append">
            <span class="input-group-text" @click="showPwdConfirm()">
              <i :class="showPasswordConfirm == true ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
            </span>
            </div>
          </div>
        </div>
        <span v-if="error" class="text-red" style="display: block; margin-top: -16px; margin-bottom: 25px;">{{ error }}</span>
        </div>
          <!-- /.col -->
          <div class="row">
            <div class="col-5">
              <button
                type="submit"
                class="btn vm-btn-submit btn-block btn-flat"
                :disabled="loading"
              >
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{ loading ? "..." : "Simpan" }}
              </button>
            </div>
            <!-- <div class="col-6 social-auth-links mb-3 ml-4">
              <router-link :to="{ name: 'forgot_password' }"
                >Lupa Kata Sandi?
              </router-link>
            </div> -->
          </div>
          <!-- /.col -->
      </form>
    </auth-layout>
  </template>
  
  <script>
  const { console } = window;
  import AuthLayout from "@components/layouts/Auth.vue";
  export default {
    components: {
      AuthLayout,
    },
    data: () => ({
      title_submit: "Simpan",
      form: {
        new_password: "",
        otp: ""
      },
      new_password_confirmation: "",
      error: '',
      loading: false,
      showPassword: false,
      showPasswordConfirm: false
    }),
    mounted() {
      $("html, body").css('overflow', 'hidden')
      // if (Api.getToken()) {
      //   this.$router.replace({ path: 'success-change-password' });
      // }
     
      $("body").attr("class", "hold-transition vm-login-page text-sm");
      $("body,html").removeAttr("style");
      new adminlte.Layout("body", {});
    },
    created() {
      this.form.otp = this.$route.query.code
    },
    methods: {
    
      async submit() {
        this.title_submit = "Processing...";
        this.loading = true;
        if(this.form.new_password === this.new_password_confirmation && this.form.new_password.length >= 8) {
          try {
            await this.Api.forgot('/forgot_password/reset', this.form)
            this.$router.replace('/success-change-password')
            this.loading = false
      
          } catch (error) {
            this.error = error.response.data.message
            this.loading = false
          }
        } else if(this.form.new_password === "" || this.form.new_password_confirmation) {
          this.error = "Kata Sandi tidak boleh kosong"
          this.loading = false
        } else if(this.form.new_password.length < 8) {
          this.error = "Kata Sandi tidak boleh kurang dari 8 karakter."
          this.loading = false
        } else {
          this.loading = false
        }
      },
      showPwd() {
        this.showPassword = !this.showPassword
      },
      showPwdConfirm() {
        this.showPasswordConfirm = !this.showPasswordConfirm
      },
      checkPwd() {
        if(this.form.new_password !== this.new_password_confirmation) {
          this.error = "Kata Sandi tidak sama.";
        } else if(this.form.new_password.length < 8) {
          this.error = "Kata Sandi tidak boleh kurang dari 8 karakter."
        }
      }
    },
  };
  </script>
  